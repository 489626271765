<template>
  <div class="max-w-173 p-8 container-height">
    <h4 class="h4 pb-5">Do you have a brand campaign?</h4>
    <div class="pb-10">
      <expandable-container
        v-for="item in brandMonitoringOptions"
        :key="item.value"
        :title="item.title"
        :content="item.content"
        :is-expanded="expandedOption === item.value"
        :class="{
          'border-bb-brand-purple shadow-drop-14': expandedOption === item.value,
          'border-neutral-50 text-bb-neutral-gray ': expandedOption !== item.value && expandedOption !== null,
        }"
        class="my-6 hover:text-black hover:border-bb-brand-purple duration-300 ease-linear"
        @click="selectBrandedMonitoringOption(item.value)"
      >
        <template #icon>
          <div
            :class="[
              'mr-5 rounded-md p-0.5 text-bb-brand-purple',
              expandedOption === item.value || expandedOption === null ? 'bg-bb-secondary-purple' : 'bg-neutral-50',
              expandedOption !== item.value && expandedOption !== null ? 'text-bb-neutral-gray' : '',
            ]"
          >
            <component
              :is="item.icon"
              size="24"
            />
          </div>
        </template>

        <template
          v-if="item.value === 'with-campaign'"
          #content
        >
          <div>
            <h5 class="h5 pb-3">Select a campaign that includes your brand terms</h5>
            <div class="divide-y divide-gray-200">
              <search-input
                id="select-campaign"
                class="pb-8 z-1000 absolute"
                :value="selectedCampaign"
                input-id="select-campaign"
                placeholder="Select Campaign"
                options-max-width="max-w-120"
                truncate-width="w-96"
                :options="campaignOptions"
                @select-item="selectCampaign"
              />
              <div
                v-if="selectedCampaign?.value && !isFetchingData"
                class="space-y-1 pt-8"
              >
                <h5 class="h5 with-campaign-table">Select your brand terms you want to monitor</h5>
                <p class="text-bb-neutral-gray pb-3">Here are the keywords we retrieved from your campaign</p>
                <top-keywords-table
                  v-if="!isFetchingData"
                  :keywords="topKeywords"
                  :selected-keywords="selectedKeywordsWithCampaign"
                  @add-keyword="addKeyword($event, item.value)"
                />
                <div>
                  <p
                    v-if="!isFetchingData"
                    class="p3 py-2 text-bb-neutral-gray"
                  >
                    Selected brand terms <span class="font-bold">{{ selectedKeywordsWithCampaign.length }}</span> of 10
                  </p>

                  <p
                    v-if="selectedKeywordsWithCampaign.length === 0 && topKeywords.length > 0"
                    class="text-xs text-bb-red-error"
                  >
                    Please select at least one brand term.
                  </p>
                </div>
                <div>
                  <div v-if="topKeywords.length > 0 && !isFetchingData">
                    <div class="flex flex-wrap gap-2">
                      <chip-tag
                        v-for="chip in selectedKeywordsWithCampaign"
                        :key="chip.value"
                        :chip="chip"
                        :fully-clickable="false"
                        :expandable="false"
                        class="bg-bb-secondary-purple text-bb-brand-purple font-medium rounded-md border-bb-purple-100 border pl-2 flex gap-2 items-center w-fit shadow-bb-shadow"
                        @click-right-icon="removeKeyword($event, item.value)"
                      >
                        <template #right-icon>
                          <span><ic-cross class="w-4 h-4" /></span>
                        </template>
                      </chip-tag>
                    </div>
                    <div class="pb-8 border-b">
                      <p class="text-sm mt-8">Missing a brand term? Add it here.</p>
                      <text-input
                        v-model="newKeyword"
                        class="w-96"
                        input-name="new-keyword"
                        input-id="new-keyword"
                        has-icon
                        padding-right="pr-20"
                        placeholder="Brand term"
                        :error="duplicateBrandedTerm"
                        :error-message="
                          duplicateBrandedTerm ? 'This term already exists. Please enter a different term.' : ''
                        "
                        @keydown.enter="handleAddKeyword"
                      >
                        <template #suffix>
                          <div class="h-10 w-24 absolute right-0 top-0 p-2">
                            <div
                              class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                              @click="handleAddKeyword"
                            >
                              <ic-plus :size="16" />
                              <p>Add</p>
                            </div>
                          </div>
                        </template>
                      </text-input>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-bb-red-error text-xs border-b border-neutral-50 pb-8">
                      Your top five keywords are unavailable.
                    </p>
                  </div>
                </div>
                <div v-if="!isFetchingData">
                  <div class="flex flex-row items-center gap-x-1 pt-8">
                    <h5 class="h5 text-bb-text-default">Location</h5>
                    <new-tooltip direction="right">
                      <ic-info
                        :size="16"
                        class="text-bb-cool-grey"
                      />
                      <template #content
                        >Monitoring brand terms across several countries will affect the result. Consider focusing on
                        <span class="font-bold">one</span>
                        location per monitor group.
                      </template>
                    </new-tooltip>
                  </div>
                  <p class="text-bb-neutral-gray">The location is retrieved from your campaign.</p>
                  <ul
                    v-if="topGeoLocations.length > 0"
                    class="flex flex-wrap gap-x-5 mb-7"
                  >
                    <li
                      v-for="location in topGeoLocations"
                      :key="location.id"
                      class="pt-3 h5 font-normal flex flex-row items-center"
                    >
                      <img
                        :src="`/content/flags/${location.country_code}.svg`"
                        alt=""
                        class="w-5 h-5 mx-1 rounded-md"
                      />
                      {{ location.canonical_name }}
                    </li>
                  </ul>
                  <p
                    v-else
                    class="text-xs text-bb-red-error"
                  >
                    No location was found.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template
          v-else-if="item.value === 'without-campaign'"
          #content
        >
          <h5 class="h5 text-bb-text-default">Select a Country</h5>
          <p class="text-bb-neutral-gray pb-3">You will only monitor keywords in this country.</p>
          <div class="divide-y divide-gray-200">
            <search-input
              id="select-country"
              class="pb-8 z-1000 absolute border-neutral-50"
              :value="selectedCountry"
              input-id="select-country"
              placeholder="Select Country"
              options-max-width="max-w-500"
              truncate-width="w-96"
              :options="countries"
              @select-item="selectCountry"
            />
            <div
              v-if="selectedCountry?.value && !isFetchingData"
              class="py-8"
            >
              <div class="flex flex-row gap-x-1 items-center pb-2">
                <h5 class="without-campaign-table h5 text-bb-text-default">
                  Select your <span class="font-bold">brand</span> terms you want to monitor
                </h5>
                <new-tooltip direction="right">
                  <ic-info
                    :size="16"
                    class="text-bb-cool-grey"
                  />
                  <template #content
                    >Note: These terms are not eligible for <span class="font-bold">smart activation</span>, only
                    monitoring insights and infringement.
                  </template>
                </new-tooltip>
                <div v-if="isFetchingData">
                  <brightbid-loader width="120" />
                </div>
              </div>
              <div
                v-if="selectedCountry?.value && !isFetchingData"
                class="space-y-1"
              >
                <top-keywords-table
                  v-if="!isFetchingData"
                  :keywords="topAccountKeywords"
                  :selected-keywords="selectedKeywordsWithoutCampaign"
                  custom-header="Keyword Terms"
                  @add-keyword="addKeyword($event, item.value)"
                />
                <div>
                  <p
                    v-if="!isFetchingData"
                    class="p3 py-2 text-bb-neutral-gray"
                  >
                    Selected brand terms
                    <span class="font-bold">{{ selectedKeywordsWithoutCampaign.length }}</span>
                    of 10
                  </p>
                  <p
                    v-if="selectedKeywordsWithoutCampaign.length === 0 && topAccountKeywords.length > 0"
                    class="text-xs text-bb-red-error"
                  >
                    Please select at least one brand term.
                  </p>
                </div>
                <div>
                  <div v-if="topAccountKeywords.length > 0 && !isFetchingData">
                    <div class="flex flex-wrap gap-2">
                      <chip-tag
                        v-for="chip in selectedKeywordsWithoutCampaign"
                        :key="chip.label"
                        :chip="chip"
                        :fully-clickable="false"
                        :expandable="false"
                        class="bg-bb-secondary-purple text-bb-brand-purple font-medium rounded-md border-bb-purple-100 border pl-2 flex gap-2 items-center w-fit capitalize shadow-bb-shadow"
                        @click-right-icon="removeKeyword($event, item.value)"
                      >
                        <template #right-icon>
                          <span><ic-cross class="w-4 h-4" /></span>
                        </template>
                      </chip-tag>
                    </div>
                    <div>
                      <p class="text-sm mt-8">Missing a brand term? Add it here.</p>
                      <text-input
                        v-model="newKeyword"
                        class="w-96"
                        input-name="new-keyword"
                        input-id="new-keyword"
                        has-icon
                        padding-right="pr-20"
                        placeholder="Brand term"
                        :error="duplicateBrandedTerm"
                        :error-message="
                          duplicateBrandedTerm ? 'This term already exists. Please enter a different term.' : ''
                        "
                        @keydown.enter="handleAddKeyword"
                      >
                        <template #suffix>
                          <div class="h-10 w-24 absolute right-0 top-0 p-2">
                            <div
                              class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                              @click="handleAddKeyword"
                            >
                              <ic-plus :size="16" />
                              <p>Add</p>
                            </div>
                          </div>
                        </template>
                      </text-input>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-xs text-bb-red-error">Your top five keywords are unavailable.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </expandable-container>
    </div>
  </div>
</template>

<script>
import ExpandableContainer from '../../components/ExpandableContainer.vue'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import TopKeywordsTable from '../../components/TopKeywordsTable.vue'

import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import ChipTag from '@/views/site/search/competitor_monitoring_v2/components/ChipTag.vue'

import IcInfo from '@/components/icon/ic-info.vue'
import IcCross from '@/components/icon/ic-cross'
import IcBullHorn from '@/components/icon/brightbid/ic-campaign.vue'
import IcKeySolid from '@/components/icon/ic-key-solid.vue'

const MAP_TYPES = {
  'with-campaign': {
    selectedKeyword: 'selectedKeywordsWithCampaign',
  },
  'without-campaign': {
    selectedKeyword: 'selectedKeywordsWithoutCampaign',
  },
}

export default {
  name: 'BrandedMonitoring',
  components: {
    ExpandableContainer,
    NewTooltip,
    TopKeywordsTable,

    SearchInput,
    TextInput,
    ChipTag,

    IcInfo,
    IcCross,
    IcBullHorn,
    IcKeySolid,
  },
  props: {
    campaignOptions: {
      type: Array,
      default: () => [],
    },
    selectedCampaign: {
      type: Object,
      default: () => ({}),
    },
    selectedCountry: {
      type: Object,
      default: () => ({}),
    },
    selectedKeywordsWithCampaign: {
      type: Array,
      default: () => [],
    },
    selectedKeywordsWithoutCampaign: {
      type: Array,
      default: () => [],
    },
    topAccountKeywords: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    topKeywords: {
      type: Array,
      default: () => [],
    },
    topGeoLocations: {
      type: Array,
      default: () => [],
    },
    isFetchingData: {
      type: Boolean,
      default: false,
    },
    savedBrandedMonitoringOption: {
      type: Boolean || null,
      default: null,
    },
  },
  data() {
    return {
      brandMonitoringOptions: [
        { title: 'Yes, I have a brand campaign.', value: 'with-campaign', icon: 'IcBullHorn' },
        { title: 'No, I don’t have a brand campaign.', value: 'without-campaign', icon: 'IcKeySolid' },
      ],
      expandedOption: null,
      input: '',
      newKeyword: '',
      accountInput: '',
      query: '',
      locationsError: false,
      duplicateBrandedTerm: false,
      maxKeywordsToSelect: 10,
    }
  },
  watch: {
    isFetchingData() {
      this.locationsError = false
    },
  },
  mounted() {
    this.expandedOption =
      this.savedBrandedMonitoringOption === null
        ? null
        : this.savedBrandedMonitoringOption
          ? 'with-campaign'
          : 'without-campaign'
  },
  methods: {
    selectBrandedMonitoringOption(value) {
      this.expandedOption = this.expandedOption === value ? this.expandedOption : value
      this.$emit('select-branded-monitoring-option', this.expandedOption)
    },
    selectCampaign(campaign) {
      this.locationsError = false
      this.$emit('select-campaign', campaign)

      this.$nextTick(() => {
        if (!this.isFetchingData) {
          const tableElement = this.$el.querySelector('.with-campaign-table')
          if (tableElement) {
            tableElement.scrollIntoView({ behavior: 'smooth' })
          }
        } else {
          // If data is still being fetched, this will wait for it to finish to scroll
          this.$watch('isFetchingData', newVal => {
            if (!newVal) {
              const tableElement = this.$el.querySelector('.with-campaign-table')
              if (tableElement) {
                tableElement.scrollIntoView({ behavior: 'smooth' })
              }
            }
          })
        }
      })
    },
    selectCountry(country) {
      this.locationsError = false
      this.$emit('select-country', country)

      this.$nextTick(() => {
        if (!this.isFetchingData) {
          const chipsElement = this.$el.querySelector('.without-campaign-table')
          if (chipsElement) {
            chipsElement.scrollIntoView({ behavior: 'smooth' })
          }
        } else {
          // If data is still being fetched, this will wait for it to finish to scroll
          this.$watch('isFetchingData', newVal => {
            if (!newVal) {
              const chipsElement = this.$el.querySelector('.without-campaign-table')
              if (chipsElement) {
                chipsElement.scrollIntoView({ behavior: 'smooth' })
              }
            }
          })
        }
      })
    },
    addKeyword(keyword, type) {
      const selectedKeywords = this[MAP_TYPES[type].selectedKeyword]

      if (!selectedKeywords) return
      if (selectedKeywords.length >= this.maxKeywordsToSelect) return
      const alreadyExists = selectedKeywords.some(item => item.label === keyword.keyword_text)

      if (!alreadyExists) {
        const keywordObj = { label: keyword.keyword_text, value: keyword.id }
        this.$emit('add-keyword', { keyword: keywordObj, type })
      }
    },
    handleAddKeyword() {
      if (!this.newKeyword.trim()) return

      const type = this.expandedOption
      const selectedKeywords = this[MAP_TYPES[type].selectedKeyword]

      const alreadyExists = selectedKeywords.some(
        item => item.label.toLowerCase() === this.newKeyword.trim().toLowerCase(),
      )

      if (alreadyExists) {
        this.duplicateBrandedTerm = true
        return
      }

      const newKeywordObject = { keyword_text: this.newKeyword.trim() }
      this.addKeyword(newKeywordObject, type)

      this.newKeyword = ''
      this.duplicateBrandedTerm = false
    },
    removeKeyword(keyword, type) {
      const selectedKeywords = this[MAP_TYPES[type].selectedKeyword]

      if (!selectedKeywords) return

      const selectedKeywordIndex = selectedKeywords.findIndex(
        item => item.value === keyword.value && item.label === keyword.label,
      )

      if (selectedKeywordIndex !== -1) {
        this.$emit('remove-keyword', { index: selectedKeywordIndex, type })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 410px);
  scroll-behavior: smooth;
}
</style>
