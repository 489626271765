<template>
  <div class="max-w-173 p-8 container-height text-bb-text-default">
    <section class="mx-auto">
      <h4 class="h4 max-w-120">Select search terms to monitor and see who else is bidding on them</h4>
    </section>
    <section>
      <h5 class="h5 text-bb-text-default pt-8">Select a Country</h5>
      <p class="text-bb-neutral-gray pb-3">You will only monitor keywords in this country</p>
      <div class="divide-y divide-neutral-50">
        <search-input
          id="select-country"
          class="pb-6 z-1000 absolute"
          :value="selectedCountry"
          input-id="select-country"
          placeholder="Select Country"
          options-max-width="max-w-120"
          truncate-width="w-96"
          :options="countries"
          @select-item="selectCountry"
        />
        <div
          v-if="selectedCountry"
          class="flex flex-row items-center gap-x-2 pb-2 pt-7"
        >
          <h5 class="h5 chips">Add Search terms from your account to monitor</h5>
          <new-tooltip direction="right">
            <ic-info
              :size="16"
              class="text-bb-disabled-gray"
            />
            <template #content>
              Note: These terms are not eligible for <span class="font-bold">smart activation</span>, only monitoring
              insights and infringement.
            </template>
          </new-tooltip>
        </div>
      </div>
      <top-keywords-table
        v-if="selectedCountry"
        :keywords="genericKeywords"
        :selected-keywords="selectedGenericKeywords"
        custom-header="Search Terms"
        @add-keyword="addKeyword($event, 'generic')"
      />
      <div v-if="selectedCountry">
        <p class="p3 py-2 text-bb-neutral-gray">
          Selected terms <span class="font-bold">{{ selectedGenericKeywords.length }}</span> of 10
        </p>

        <div
          v-if="selectedGenericKeywords.length > 0"
          class="flex flex-wrap gap-2 pb-8"
        >
          <chip-tag
            v-for="chip in selectedGenericKeywords"
            :key="chip.value"
            :chip="chip"
            :fully-clickable="false"
            :expandable="false"
            class="bg-bb-secondary-purple text-bb-brand-purple font-medium rounded-md border-bb-purple-100 border pl-2 flex gap-2 items-center w-fit capitalize shadow-bb-shadow"
            @click-right-icon="removeKeyword($event, 'generic')"
          >
            <template #right-icon>
              <span><ic-cross class="w-4 h-4" /></span>
            </template>
          </chip-tag>
        </div>
        <div>
          <p class="text-sm">Missing a brand term? Add it here.</p>
          <text-input
            v-model="newKeyword"
            class="w-96"
            input-name="new-keyword"
            input-id="new-keyword"
            has-icon
            padding-right="pr-20"
            placeholder="Brand term"
            :error="duplicateBrandedTerm"
            :error-message="duplicateBrandedTerm ? 'This term already exists. Please enter a different term.' : ''"
            @keydown.enter="handleAddKeyword"
          >
            <template #suffix>
              <div class="h-10 w-24 absolute right-0 top-0 p-2">
                <div
                  class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                  @click="handleAddKeyword"
                >
                  <ic-plus :size="16" />
                  <p>Add</p>
                </div>
              </div>
            </template>
          </text-input>
        </div>
        <p
          v-if="selectedGenericKeywords.length === 0"
          class="text-xs text-bb-red-error"
        >
          Please select at least one brand term.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import TopKeywordsTable from '../../components/TopKeywordsTable.vue'
import ChipTag from '@/views/site/search/competitor_monitoring_v2/components/ChipTag.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'

import IcCross from '@/components/icon/ic-cross'
import IcInfo from '@/components/icon/ic-info.vue'

const MAP_TYPES = {
  generic: {
    selectedKeyword: 'selectedGenericKeywords',
  },
}

export default {
  name: 'GenericMonitoring',
  components: { SearchInput, NewTooltip, IcInfo, TopKeywordsTable, ChipTag, TextInput, IcCross },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
    genericKeywords: {
      type: Array,
      default: () => [],
    },
    selectedCountry: {
      type: Object,
      default: () => ({}),
    },
    selectedGenericKeywords: {
      type: Array,
      default: () => [],
    },
    isFetchingData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locationsError: false,
      duplicateBrandedTerm: false,
      maxKeywordsToSelect: 10,
      newKeyword: '',
    }
  },
  watch: {
    isFetchingData() {
      this.locationsError = false
    },
  },
  methods: {
    selectCountry(country) {
      this.$emit('select-country', country)
      this.$nextTick(() => {
        if (!this.isFetchingData) {
          const chipsElement = this.$el.querySelector('.chips')
          if (chipsElement) {
            chipsElement.scrollIntoView({ behavior: 'smooth' })
          }
        } else {
          // If data is still being fetched, this will wait for it to finish to scroll
          this.$watch('isFetchingData', newVal => {
            if (!newVal) {
              const chipsElement = this.$el.querySelector('.chips')
              if (chipsElement) {
                chipsElement.scrollIntoView({ behavior: 'smooth' })
              }
            }
          })
        }
      })
    },
    addKeyword(keyword, type) {
      const selectedKeywords = this[MAP_TYPES[type].selectedKeyword]

      if (!selectedKeywords) return
      if (selectedKeywords.length >= this.maxKeywordsToSelect) return

      const alreadyExists = selectedKeywords.some(item => item.value === keyword.id)

      if (!alreadyExists) {
        const keywordObj = { label: keyword.keyword_text, value: keyword.id }
        this.$emit('add-keyword', { keyword: keywordObj, type })
      }
    },
    handleAddKeyword() {
      if (!this.newKeyword.trim()) return

      const type = 'generic'
      const selectedKeywords = this[MAP_TYPES[type].selectedKeyword]

      const alreadyExists = selectedKeywords.some(
        item => item.label.toLowerCase() === this.newKeyword.trim().toLowerCase(),
      )

      if (alreadyExists) {
        this.duplicateBrandedTerm = true
        return
      }

      const newKeywordObject = { keyword_text: this.newKeyword.trim() }
      this.addKeyword(newKeywordObject, type)

      this.newKeyword = ''
      this.duplicateBrandedTerm = false
    },
    removeKeyword(keyword, type) {
      const selectedKeywords = this[MAP_TYPES[type].selectedKeyword]

      if (!selectedKeywords) return

      const selectedKeywordIndex = selectedKeywords.findIndex(
        item => item.value === keyword.value && item.label === keyword.label,
      )

      if (selectedKeywordIndex !== -1) {
        this.$emit('remove-keyword', { index: selectedKeywordIndex, type })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 410px);
  scroll-behavior: smooth;
}
</style>
